import Alpine from "alpinejs"

// TODO: Migrate to new Vidstack
import 'vidstack/styles/defaults.css'
// import 'vidstack/styles/community-skin/video.css'

import { defineCustomElements } from 'vidstack/elements';

defineCustomElements();


console.log("challenge-details entrypoint")

export async function challengeDetails(sectionIndex, video, image) {
  console.log('video', video)
}

Alpine.data("challengeDetails", challengeDetails)
